<template>
  <div class="upload-image-comp">
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      max-width="600px"
    >
      <v-card class="upload-image-comp pb-4">
        <v-card-title class="px-2">
          <v-row no-gutters>
            <v-col>
              <span class="headline">Profile Photo</span>
            </v-col>
            <v-col cols="auto" v-if="imageData !== null && picture !== null">
              <v-btn text small @click="removePhoto">remove photo <v-icon>mdi-image</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>

            <!-- Inner container -->
            <template v-if="uploadValue == 0 && picture == null">
              <p>Show everyone who you are:</p>
              <v-card class="grey lighten-3 pa-5">
                <input type="file" @change="previewImage" accept="image/*" >
              </v-card>
            </template>

            <template v-if="uploadValue > 0 && imageData != null && picture == null">
              <div class="text-center">
                <!-- <p>Progress: {{uploadValue.toFixed()+"%"}}
                <progress id="progress" :value="uploadValue" max="100" ></progress>  </p> -->
                <div class="text-h5">
                  Uploading...
                </div>
                <v-progress-linear
                v-model="uploadValue"
                color="primary"
                height="20"
              >
                <strong>{{ Math.ceil(uploadValue) }}%</strong>
              </v-progress-linear>
              </div>
            </template>

            <div v-if="uploadValue == 100 && picture != null" class="text-center image-container mx-auto">
              <!-- <img class="preview" :src="picture"> -->
              <!-- <div class="preview"></div> -->
              <!-- <cropper
                  class="cropper"
                  ref="cropper"
                  :src="picture"
                  :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                    }"
                  :resize-image="{adjustStencil: false}"
                  image-restriction="stencil"
                ></cropper> -->
                <cropper
                  ref="cropper"
                  class="twitter-cropper"
                  background-class="twitter-cropper__background"
                  foreground-class="twitter-cropper__foreground"
                  image-restriction="stencil"
                  default-boundaries="fill"
                  :stencil-size="stencilSize"
                  :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                    previewClass: 'twitter-cropper__stencil',
                  }"
                  :transitions="false"

                  :debounce="false"
                  :default-size="defaultSize"
                  :min-width="150"
                  :min-height="150"
                  :src="picture"
                  @change="onChange"
                />
                <zoomer :zoom="zoom" @change="onZoom" />
                <div class="mt-4">

                <v-divider></v-divider>


                </div>

            </div>
            <!-- end -->



        </v-card-text>
        <v-card-actions>
          <v-btn
          color="red darken-1"
          text
          @click="closeModal"
          >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
          <template v-if="picture == null && uploadValue == 0">
            <v-btn @click="onUpload" color="primary" :disabled="imageData == null">
              Upload Photo
            </v-btn>
          </template>
          <template v-if="picture != null">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="finalizeImage"
              >
              Looks Good!
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
const zoomer = () => import("@/components/shared/zoomer.vue");
import { storage } from '@/firebase';
import {imageConversion, compress, canvastoDataURL} from 'image-conversion';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'uploadImage',
  props:['previousPhoto'],
  computed: {
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
  },
  components:{
    Cropper, zoomer
  },
  data: () => ({
    imageData: null,
    preview:null,
    cropImg:null,
    picture: null,
    // picture: "https://firebasestorage.googleapis.com/v0/b/meetable-abf7e.appspot.com/o/GuRU6JRIzvWWRFtgS3KviRJKhql2%2Fprofile_photo%2Foriginal?alt=media&token=a1266e1d-065c-428c-8bbd-bc322793f574",
    uploadValue: 0,
    // uploadValue: 100,
    dialog:false,
    images:{},
    zoom:0,
    coordinates: null,
    canvas: null
  }),
  methods:{
    removePhoto(){
      process.env.NODE_ENV === "development" ? console.log( 'button' ) : null;
      this.picture = null;
      this.uploadValue = 0;
      this.imageData = null;
    },
    closeModal(){
      this.dialog = false;
      this.$emit('closeModal', false);
    },
    previewImage(event) {
      this.uploadValue=0;
      this.picture=null;
      this.imageData = event.target.files[0];

    },
    onUpload(){
      let vThis = this;
      // this.picture=null;
      this.$compress(this.imageData, {quality:.8, width:600}).then(resizedImg =>{
        // const storageRef = storage.ref(`${vThis.userId}/profile_photo/original`).put(resizedImg);
        // storageRef.on(`state_changed`,snapshot=>{
        //   vThis.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        // }, error=>{console.log(error.message)},
        // ()=>{
        //   vThis.uploadValue=100;
        // });
        vThis.uploadStorage('original', resizedImg, 'picture').then(result =>{
          // process.env.NODE_ENV === "development" ? console.log( 'results from init phoot', result ) : null;

        })


      })
    },
    finalizeImage(){
      let vThis = this;
      const { coordinates, canvas} = vThis.$refs.cropper.getResult();
      process.env.NODE_ENV === "development" ? console.log( vThis.$refs.cropper.getResult() ) : null;
			// let message = canvas.toDataURL();
      vThis.$canvastoFile(canvas, {quality:.8})
        .then(croppedImg => vThis.uploadStorage('cropped', croppedImg).then(x =>{
        vThis.$compress(croppedImg, {quality:.9, width:200})
        .then(croppedThumb => vThis.uploadStorage('thumb', croppedThumb))
        .then(x =>{
          process.env.NODE_ENV === "development" ? console.log( 'done', vThis.images ) : null;
          vThis.dialog = false;
          vThis.$emit('closeModal', false);
        })
      }))

      // .then(result => vThis.$compress(this.imageData, {quality:.9, width:200}))
      // .then(resizedImg => {
      //   vThis.uploadStorage('thumb', resizedImg).then((x) => {
      //   });
      // });
    },
    async uploadStorage(location, image, getImage){
      let vThis = this;
      let storageRef = storage.ref(`${vThis.userId}/profile_photo/${location}`).put(image)

      let result = await storageRef.on(`state_changed`,snapshot=>{
        vThis.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        process.env.NODE_ENV === "development" ? console.log( 'top area' ) : null;
      }, error=>{
        console.log(error.message)
      },
      async () =>{
        let url = await storageRef.snapshot.ref.getDownloadURL().then((link) => {
          return link
        }).catch(error => {
          process.env.NODE_ENV === "development" ? console.log( 'error', error.code ) : null;
        });
        vThis.images[`${location}`] = url;
        //send to data store
        let data =  {location: location, url:url};
        vThis.$store.dispatch('storePhotos', {data});
        if(location == 'original'){vThis.picture = url}
        process.env.NODE_ENV === "development" ? console.log( 'url', url ) : null;
        process.env.NODE_ENV === "development" ? console.log( 'url', vThis.images ) : null;
        return url;
      });
      return result
    },
    defaultSize({ imageSize }) {
     return {
       width: imageSize.width,
       height: imageSize.height,
     };
   },
   stencilSize({ boundaries }) {
     return {
       width: Math.min(boundaries.height, boundaries.width) - 48,
       height: Math.min(boundaries.height, boundaries.width) - 48,
     };
   },
   onChange(result) {
     const cropper = this.$refs.cropper;
     if (cropper) {
       const { coordinates, imageSize } = cropper;
       if (
         imageSize.width / imageSize.height >
         coordinates.width / coordinates.height
       ) {
         this.zoom =
           (cropper.imageSize.height - cropper.coordinates.height) /
           (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
       } else {
         this.zoom =
           (cropper.imageSize.width - cropper.coordinates.width) /
           (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
       }
     }
   },
   onZoom(value) {
     const cropper = this.$refs.cropper;
     if (cropper) {
       const { coordinates, imageSize } = cropper;
       if (
         imageSize.width / imageSize.height >
         coordinates.width / coordinates.height
       ) {
         const minHeight = cropper.sizeRestrictions.minHeight;
         cropper.zoom(
           ((1 - this.zoom) * cropper.imageSize.height + minHeight) /
             ((1 - value) * cropper.imageSize.height + minHeight)
         );
       } else {
         const minWidth = cropper.sizeRestrictions.minWidth;
         cropper.zoom(
           ((1 - this.zoom) * cropper.imageSize.width + minWidth) /
             ((1 - value) * cropper.imageSize.width + minWidth)
         );
       }
     }
   },
  },
  created(){
    this.imageData = null;
    this.dialog = true;
    // process.env.NODE_ENV === "development" ? console.log( this.$compress ) : null;
  }
};




</script>


<style lang="scss">
.upload-image-comp{
  .image-container{
    width:320px;

    .cropper-container{
      max-height: 320px!important;
      max-width: 320px;
      .cropper-crop.box{
        transform: translateY(0)!important;
      }
    }
  }
  .twitter-cropper {
  height: 320px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
  .preview{
    max-width: 360px;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    > div img{
      max-width:100%;
      max-height: 30vh;
    }
  }
  .zoomer{
    width:40px;
    height:320px;
    position: absolute;
    left: 364px;
    top: 45px;
  }
}
</style>
